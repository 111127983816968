import Button from '@/components/atoms/Button/Button';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import { EstimatedCalculator } from '@/components/molecules/EstimatedCalculator';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import {
  CALCULATOR_DISCLAIMER,
  CALCULATOR_DISCLAIMER_LONG,
} from '@/lib/constants';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { CalculatorType } from './types';

interface CalculatorWrapperProps {
  type: CalculatorType;
  mode: 'Finance' | 'Lease';
  inputs: ReactElement;
  breakdown: ReactElement;
  handleOfferButton?: () => void;
  monthlyEstimatedPayment: number;
  handleChangeMonthlyEstimatedPayment: (estimatedPayment: string) => void;
  handleChangeMode: (val: 'Finance' | 'Lease') => void;
}

export const CalculatorWrapper: React.FC<CalculatorWrapperProps> = ({
  type,
  mode,
  handleChangeMode,
  monthlyEstimatedPayment,
  handleChangeMonthlyEstimatedPayment,
  handleOfferButton,
  inputs,
  breakdown,
}) => {
  const {
    settings: { evSavings },
  } = useVehicleFilterSettingsContext();
  let estimatedValueTitle = evSavings ? 'Payment after EV Savings' : 'Payment';
  if (type === 'vlp') estimatedValueTitle = 'Payment After Est. Savings';

  return (
    <div
      className={clsx('flex h-full w-full flex-col bg-white', {
        'rounded-b-small rounded-t-small border border-neutral-200':
          type === 'vdp',
        'rounded-t-small': type === 'homepage',
      })}
    >
      {type === 'vlp' && (
        <div className="flex w-full justify-between bg-neutral-0 px-xl pb-s pt-xl">
          <div className="text-body2Light text-neutral-800">
            Payment Calculator
          </div>
        </div>
      )}
      <div
        className={clsx('flex flex-col', {
          'h-full justify-between l:flex-row': type === 'homepage',
          'ml:flex-row': type === 'vdp',
          'm:flex-row': type === 'vlp',
        })}
      >
        <div
          className={clsx(
            'flex w-full flex-col gap-s rounded-t-small p-l l:rounded-l-small l:rounded-tr-none l:p-xl',
            {
              'l:w-1/2': type === 'homepage',
              'ml:w-1/2 l:w-1/3': type === 'vdp',
              'm:w-1/2': type === 'vlp',
            }
          )}
        >
          {type === 'vdp' && (
            <>
              <div className="text-h3SemiBold">Payment Calculator</div>
              <div className="py-l">
                <Switcher
                  aria-label="Finance or Lease"
                  options={[
                    {
                      text: 'Finance',
                      value: 'Finance',
                    },
                    { text: 'Lease', value: 'Lease' },
                  ]}
                  value={mode}
                  onChange={(val) => {
                    handleChangeMode(val as 'Finance' | 'Lease');
                  }}
                />
              </div>
            </>
          )}
          {inputs}
        </div>
        <div
          className={clsx('flex w-full flex-col rounded-b-small', {
            'l:w-1/2': type === 'homepage',
            'ml:w-1/2 l:w-2/3': type === 'vdp',
            'm:w-1/2': type === 'vlp',
          })}
        >
          <div
            className={clsx('rounded-[0px] bg-neutral-200 px-xl', {
              'py-l ml:rounded-tr-small': type === 'vdp',
              'py-s pt-l l:py-l': type === 'homepage',
            })}
          >
            <EstimatedCalculator
              title={estimatedValueTitle}
              monthlyEstimatedPayment={monthlyEstimatedPayment}
              changeMonthlyEstimatedPayment={
                handleChangeMonthlyEstimatedPayment
              }
              editable={type === 'homepage' || type === 'vlp'}
            />
          </div>
          <div
            className={clsx(
              'flex flex-col rounded-b-small bg-white l:rounded-bl-[0px] l:rounded-br-small',
              {
                'hidden p-0 l:flex l:py-xl l:pl-l l:pr-xl': type === 'homepage',
                'h-full justify-between px-xl py-l ml:py-xl ml:pl-l ml:pr-xl':
                  type === 'vdp',
              }
            )}
          >
            {breakdown}
            <div
              className={clsx('flex h-full flex-col justify-end', {
                'pb-xl pt-l': type !== 'homepage',
              })}
            >
              {type === 'vdp' && handleOfferButton && (
                <>
                  <div className="flex w-full">
                    <Button aria-label="Send Offer" onClick={handleOfferButton}>
                      Send Offer
                    </Button>
                  </div>

                  <span className="pt-l text-microLight text-neutral-800">
                    {CALCULATOR_DISCLAIMER}{' '}
                    <ToolTip
                      aria-label="View disclaimer tool tip"
                      hoverElement={
                        <div className="text-microMedium text-blue-medium">
                          View disclaimer
                        </div>
                      }
                    >
                      {CALCULATOR_DISCLAIMER_LONG}
                    </ToolTip>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BreakdownSectionDivider: React.FC<{ strong?: boolean }> = ({
  strong,
}) => {
  return (
    <div
      className={`h-px ${strong ? 'bg-neutral-800' : 'bg-neutral-200'} rounded-small`}
    />
  );
};

export const BreakdownItem: React.FC<{
  label: string;
  tooltip?: string;
  val: string;
}> = ({ label, tooltip, val }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-xs">
        <div className="text-body2Light text-neutral-900">{label}</div>
        {tooltip ? (
          <ToolTip
            aria-label={`${label} tooltip`}
            hoverElement={
              <InformationCircleIcon className="size-l fill-neutral-800" />
            }
          >
            <div>{tooltip}</div>
          </ToolTip>
        ) : null}
      </div>
      <div className="text-body2Light text-neutral-900">{val}</div>
    </div>
  );
};

export const Total: React.FC<{
  label: string;
  monthlyTotal: number;
  overallTotal?: number;
}> = ({ label, monthlyTotal, overallTotal }) => {
  return (
    <div className="flex justify-between">
      <div className="text-body2Medium text-neutral-900">{label}</div>
      <div className="text-body2Light text-neutral-900">
        {overallTotal && `$${Math.round(overallTotal).toLocaleString()} or `}
        <span className="text-body2Medium text-neutral-900">
          ${Math.round(monthlyTotal).toLocaleString()}/mo
        </span>
      </div>
    </div>
  );
};
