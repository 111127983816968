import { FullStoryContext } from '@/context/FullStory';
import { useContext } from 'react';

export const useFullStoryContext = () => {
  const context = useContext(FullStoryContext);
  const isProd = process.env.ENABLE_DEV !== 'true';
  if (context === undefined)
    throw new Error(
      'useFullStoryContext must be used within a FullStoryContextProvider'
    );

  if (!isProd) {
    throw new Error('useFullStoryContext must be used in production');
  }
  return context;
};
