import { DynamicImageWrapper } from '@/components/molecules/DynamicImageWrapper/DynamicImageWrapper';
import { HeartButton } from '@/components/molecules/HeartButton';
import React, { FC } from 'react';

interface VLPCardImageContainerProps {
  image: string;
  make: string;
  model: string;
  year: string;
  isMobile: boolean;
  isColumnLayout: boolean;
  handleSaveVehicle: (
    e?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => Promise<void>;
  saved: boolean;
  savingVehicle: boolean;
}

export const VLPCardImageContainer: FC<VLPCardImageContainerProps> = ({
  image,
  make,
  model,
  year,
  isMobile,
  isColumnLayout,
  handleSaveVehicle,
  saved,
  savingVehicle,
}) => {
  return (
    <div className="relative flex h-full w-full">
      <div className="absolute z-10 flex p-m">
        <HeartButton
          authDialogImage={image}
          size={isMobile ? 'medium' : 'small'}
          saved={saved}
          onClick={handleSaveVehicle}
          disabled={savingVehicle}
        />
      </div>
      <div
        className={`flex h-full w-full items-center overflow-hidden ${
          !isMobile && isColumnLayout ? 'rounded-t-lg' : 'rounded-l-lg'
        }`}
      >
        <DynamicImageWrapper
          ariaLabel={`link to build ${year} ${make} ${model}`}
          make={make}
          className="!object-cover"
          src={image}
          alt={`build ${year} ${make} ${model}`}
          priority={true}
          isColumnLayout={isColumnLayout}
          ratio={0.5}
        />
      </div>
    </div>
  );
};
